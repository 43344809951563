import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { TargetType } from 'api/firmware-api';
import { Field } from 'components/Field/Field';
import { UseApplicationFormReturn } from 'helpers/useApplicationForm';
import { FirmwareReleaseModel, FirmwareReleases } from '../../utils';

import styles from './PartialReleasePercentageField.module.scss';

export type PartialReleasePercentageFieldProps = {
  releaseIndex: number;
  release: FirmwareReleaseModel;
  form: UseApplicationFormReturn<FirmwareReleases, any>;
};

export const PartialReleasePercentageField: React.FC<PartialReleasePercentageFieldProps> = ({
  form: { register, control, formState },
  release,
  releaseIndex,
}) => {
  const errors = formState.errors.releases?.[releaseIndex];

  const partialReleasePercentage = register(`releases.${releaseIndex}.partialReleasePercentage`, {
    required: { value: release.type === TargetType.ByWhiteList, message: 'Required' },
    min: { value: 1, message: 'Percentage cannot be less than 1%' },
    max: { value: 100, message: 'Percentage can not exceed 100%' },
    value: 100,
  });

  return (
    <Field title={'Share'} fieldClassName={styles.shareInput}>
      <Controller
        control={control}
        name={partialReleasePercentage.name}
        render={({ field }) => (
          <TextField
            {...field}
            sx={{ width: '100%' }}
            type="number"
            error={!!errors?.partialReleasePercentage}
            helperText={errors?.partialReleasePercentage?.message}
          />
        )}
      />
      <span className={styles.percentageSymbol}>%</span>
    </Field>
  );
};
