import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { useFieldArray } from 'react-hook-form';

import { FirmwareApi, FirmwareTargetDto } from 'api/firmware-api';
import AddIcon from 'assets/icons/add.svg';
import { AppForm } from 'components/AppForm/AppForm';
import { AppModal } from 'components/AppModal/AppModal';
import { getIsFormErrored } from 'helpers/react-hook-form-helpers';
import { useInvalidateFirmwarePageQuery } from 'helpers/react-query/firmware-query-hooks';
import { useApplicationForm } from 'helpers/useApplicationForm';
import {
  SelectedFirmwareFile,
  SelectedFirmwareFileContext,
} from '../../DeviceFirmwareListsPage/DeviceFirmwareListsPage';
import { FirmwareRelease } from './FirmwareRelease/FirmwareRelease';
import {
  defaultRelease,
  FirmwareReleaseModel,
  FirmwareReleases,
  firmwareTargetDtoToModel,
  firmwareTargetModelToDto,
} from './utils';

import styles from './CreateReleasesModal.module.scss';

export const CreateReleasesModal: React.FC = () => {
  const { selectedFile, setSelectedFile } = useContext(SelectedFirmwareFileContext);

  return selectedFile && <Modal selectedFile={selectedFile} setSelectedFile={setSelectedFile} />;
};

const Modal: React.FC<{
  selectedFile: SelectedFirmwareFile;
  setSelectedFile: React.Dispatch<React.SetStateAction<SelectedFirmwareFile | null>>;
}> = ({ selectedFile, setSelectedFile }) => {
  const { file } = selectedFile;
  const form = useApplicationForm<FirmwareReleases>({
    mode: 'onBlur',
    values: {
      releases: file.targets.length ? file.targets.map(firmwareTargetDtoToModel) : [defaultRelease],
    },
  });
  const { control, handleSubmit, formState, clearErrors } = form;
  const formHasErrors = getIsFormErrored(formState);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'releases',
  });

  const invalidate = useInvalidateFirmwarePageQuery();
  const onClose = () => setSelectedFile(null);

  const uploadFileTargetsMutation = useMutation(
    (targets: FirmwareTargetDto[]) => FirmwareApi.updateTargets(file.id, targets),
    {
      onSuccess: () => {
        invalidate();
        onClose();
      },
    },
  );

  // We should set undefined to id, because duplicated release shouldn't
  // have an id so as not to update the original release.
  const handleDuplicate = (release: FirmwareReleaseModel) =>
    append({
      ...release,
      id: undefined,
      distributionWays: release.distributionWays.map((distribution) => ({ ...distribution, id: undefined })),
    });

  const onSubmit = async (data: FirmwareReleases) =>
    await uploadFileTargetsMutation.mutateAsync(data.releases.map(firmwareTargetModelToDto));

  const actions = [
    <Button color="secondary" variant="outlined" key={'cancel'} onClick={onClose}>
      Cancel
    </Button>,
    <Button type="submit" key={'save'} disabled={formHasErrors}>
      Save
    </Button>,
  ];

  return (
    <AppModal open={selectedFile.selectionType === 'settingRelease'} onClose={onClose}>
      <AppForm
        title="Set firmware release"
        description={`${file.firmwareGeneration}, FM ver ${file.version}`}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={uploadFileTargetsMutation.isLoading}
        actions={actions}
        helperText={formState.errors.root?.message}
        error={formHasErrors}
        onChange={() => clearErrors()}
      >
        <div className={styles.releasesModal}>
          {fields.map((release, index) => (
            <FirmwareRelease
              key={release.id}
              index={index}
              form={form}
              onDuplicate={handleDuplicate}
              onDelete={remove}
            />
          ))}
        </div>
        <Button
          className={styles.addReleaseButton}
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => append(defaultRelease)}
          key={'add'}
        >
          Add release date
        </Button>
      </AppForm>
    </AppModal>
  );
};
