import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { getDistributionConstraints, TargetType } from 'api/firmware-api';
import { Field } from 'components/Field/Field';
import { UseApplicationFormReturn } from 'helpers/useApplicationForm';
import { DistributionWay, FirmwareReleaseModel, FirmwareReleases } from '../../utils';

export type TargetTypeFieldProps = {
  form: UseApplicationFormReturn<FirmwareReleases, any>;
  releaseIndex: number;
  release: FirmwareReleaseModel;
};

export const TargetTypeField: React.FC<TargetTypeFieldProps> = ({
  form: { register, control, setValue },
  releaseIndex,
  release,
}) => {
  const field = register(`releases.${releaseIndex}.type`, {
    onChange: (e) =>
      setValue(
        `releases.${releaseIndex}.distributionWays`,
        filterDistributionsForTargetType(release.distributionWays, e.target.value),
      ),
  });

  return (
    <Field title={'Distribution type'}>
      <Controller
        control={control}
        name={field.name}
        render={({ field }) => (
          <RadioGroup row {...field}>
            <FormControlLabel value={TargetType.ByCountry} control={<Radio />} label={'Countries'} />
            <FormControlLabel value={TargetType.ByWhiteList} control={<Radio />} label={'White list'} />
          </RadioGroup>
        )}
      />
    </Field>
  );
};

const filterDistributionsForTargetType = (distributions: DistributionWay[], targetType: TargetType) =>
  distributions.filter((x) => getDistributionConstraints(x.type).targetTypes.includes(targetType));
