import React from 'react';
import { Controller } from 'react-hook-form';

import { AppDateTimePicker } from 'components/AppDateTimePicker/AppDateTimePicker';
import { Field } from 'components/Field/Field';
import { UseApplicationFormReturn } from 'helpers/useApplicationForm';
import { FirmwareReleases } from '../../utils';

export type StartDateFieldProps = {
  form: UseApplicationFormReturn<FirmwareReleases, any>;
  releaseIndex: number;
};

export const StartDateField: React.FC<StartDateFieldProps> = ({
  form: { control, formState, register },
  releaseIndex,
}) => {
  const errors = formState.errors.releases?.[releaseIndex];
  const startDate = register(`releases.${releaseIndex}.startDate`, {
    required: { value: true, message: 'Required' },
    validate: (val: Date) => (isNaN(+val) ? 'Invalid date' : true),
  });

  return (
    <Field title={'Release date'}>
      <Controller
        control={control}
        name={startDate.name}
        render={({ field }) => (
          <AppDateTimePicker
            type="dateOnly"
            error={!!errors?.startDate}
            helperText={errors?.startDate?.message}
            {...field}
          />
        )}
      />
    </Field>
  );
};
