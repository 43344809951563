import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { AppHint } from 'components/AppHint/AppHint';

import styles from './CheckboxGroup.module.scss';

export type CheckboxGroupOption = {
  value: string;
  label: string;
  selected: boolean;
  disabled?: boolean;
  contentHint?: string;
  hint?: string;
  id?: any;
};

type Props = {
  options: CheckboxGroupOption[];
  onChange: (value: string, checked: boolean) => void;
  error?: boolean;
  helperText?: React.ReactNode;
};

export const CheckboxGroup: React.FC<Props> = ({ options, onChange, error, helperText }) => (
  <FormControl error={error}>
    <FormGroup sx={{ gap: '10px' }}>
      {options.map((option, index) => (
        <div className={styles.labelContainer} key={option.id ?? index}>
          <AppHint
            openDelay={500}
            closeDelay={100}
            component={
              <FormControlLabel
                sx={{ marginRight: '4px' }}
                checked={option.selected}
                onChange={(_, checked) => onChange(option.value, checked)}
                control={
                  <Checkbox size="small" sx={{ color: '#A6A6A6', padding: '2px 9px' }} disabled={option.disabled} />
                }
                label={option.label}
                value={option.value}
              />
            }
          >
            {option.contentHint}
          </AppHint>
          {option.hint && <AppHint openDelay={100}>{option.hint}</AppHint>}
        </div>
      ))}
    </FormGroup>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);
