import React from 'react';

import {
  DistributionType,
  DistributionWayDto,
  FirmwareTargetDto,
  getDistributionConstraints,
  TargetType,
} from 'api/firmware-api';
import { addWhiteListFileExtension } from 'features/FirmwarePage/WhiteLists/utils';
import { formatAsDate } from 'helpers/dateUtils';
import { useIsoCodes } from 'helpers/useIsoCodes';

import styles from './FirmwareTarget.module.scss';

export type FirmwareReleaseProps = {
  target: FirmwareTargetDto;
};

export const FirmwareTarget: React.FC<FirmwareReleaseProps> = ({ target }) => {
  const { getCountryName } = useIsoCodes();

  return (
    <div>
      <div>
        <strong>
          {`${formatAsDate(target.startDate)}- ${
            target.type === TargetType.ByCountry
              ? target.countryTarget?.countries
                ? target.countryTarget?.countries.map((country) => getCountryName(country)).join(', ')
                : 'All countries'
              : target.whiteListTarget?.whiteList.name &&
                addWhiteListFileExtension(target.whiteListTarget?.whiteList.name)
          }`}
        </strong>
        {target.type === TargetType.ByWhiteList && (
          <span className={styles.percentage}>{target.whiteListTarget?.partialReleasePercentage}%</span>
        )}
      </div>
      <DistributionWays distributionWays={target.distributionWays} />
    </div>
  );
};

type DistributionWaysProps = {
  distributionWays: DistributionWayDto[];
};

const DistributionWays: React.FC<DistributionWaysProps> = ({ distributionWays }) => (
  <div>
    {distributionWays
      .map(
        (distribution) =>
          getDistributionConstraints(distribution.type).label +
          (distribution.type === DistributionType.PrismaCloud ? writeStages(distribution.byPrismaCloud?.stages) : ''),
      )
      .join(', ')}
  </div>
);

const writeStages = (stages?: string[]) => (stages ? ` (${stages.join(', ')})` : ' (All stages)');
