// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shareInput_Kr1LE{display:flex;flex-direction:row;gap:10px}.percentageSymbol_HGDyK{padding-top:10px}`, "",{"version":3,"sources":["webpack://./src/features/FirmwarePage/Files/Forms/CreateReleases/FirmwareRelease/PartialReleasePercentageField/PartialReleasePercentageField.module.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,wBACE,gBAAA","sourcesContent":["@import '/src/styles/variables.scss';\n\n.shareInput {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n}\n\n.percentageSymbol {\n  padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareInput": `shareInput_Kr1LE`,
	"percentageSymbol": `percentageSymbol_HGDyK`
};
export default ___CSS_LOADER_EXPORT___;
