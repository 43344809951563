import React, { useContext } from 'react';
import { FieldPath, useFieldArray } from 'react-hook-form';

import {
  DistributionConstraints,
  DistributionType,
  DistributionWayConstraints,
  FirmwareRecordDto,
  getFirmwareContentTypeName,
  getFirmwareGenerationInfo,
  TargetType,
} from 'api/firmware-api';
import { CheckboxGroup, CheckboxGroupOption } from 'components/CheckBoxGroup/CheckboxGroup';
import { Field } from 'components/Field/Field';
import { SelectedFirmwareFileContext } from 'features/FirmwarePage/Files/DeviceFirmwareListsPage/DeviceFirmwareListsPage';
import { UseApplicationFormReturn } from 'helpers/useApplicationForm';
import { FirmwareReleaseModel, FirmwareReleases } from '../../utils';

export type DistributionWaysFieldProps = {
  form: UseApplicationFormReturn<FirmwareReleases, any>;
  releaseIndex: number;
  release: FirmwareReleaseModel;
};

export const DistributionWaysField: React.FC<DistributionWaysFieldProps> = ({
  form: { control, formState, register },
  releaseIndex,
  release,
}) => {
  const errors = formState.errors.releases?.[releaseIndex];
  const path: FieldPath<FirmwareReleases> = `releases.${releaseIndex}.distributionWays`;

  const {
    fields: distributions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: path,
  });

  register(path, {
    required: { value: true, message: 'Select at least one distribution way' },
  });

  const options = useDistributionOptions(
    release.type,
    distributions.map((x) => x.type),
  );

  const handleChange = (type: DistributionType, checked: boolean) =>
    checked ? append({ type, allStages: false, stages: [] }) : remove(distributions.findIndex((x) => x.type === type));

  return (
    <Field title={'Distribute by'}>
      <CheckboxGroup
        error={!!errors?.distributionWays}
        helperText={errors?.distributionWays?.root?.message}
        options={options}
        onChange={(type, checked) => handleChange(type as DistributionType, checked)}
      />
    </Field>
  );
};

const useDistributionOptions = (releaseType: TargetType, distributions: DistributionType[]): CheckboxGroupOption[] => {
  const { selectedFile } = useContext(SelectedFirmwareFileContext);
  if (!selectedFile) return [];

  return DistributionConstraints.filter((x) => x.targetTypes.includes(releaseType)).map((x) => {
    const { isCompatible, reason } = isDistributionCompatible(selectedFile.file, x);

    return {
      value: x.type,
      selected: distributions.includes(x.type),
      disabled: !isCompatible,
      contentHint: reason,
      hint: x.hint,
      label: x.label,
    };
  });
};

const isDistributionCompatible = (record: FirmwareRecordDto, distribution: DistributionWayConstraints) => {
  const compatibleGeneration = distribution.generations.includes(record.firmwareGeneration);
  const compatibleContent = record.availableContent.includes(distribution.requiredContent);

  let reason = undefined;
  if (!compatibleGeneration)
    reason = `Generation ${getFirmwareGenerationInfo(record.firmwareGeneration).name} cannot be distributed by ${
      distribution.label
    }`;
  else if (!compatibleContent)
    reason = `The following content is required in order to distribute the update by ${
      distribution.label
    }: ${getFirmwareContentTypeName(distribution.requiredContent)}`;

  return {
    isCompatible: compatibleGeneration && compatibleContent,
    reason: reason,
  };
};
