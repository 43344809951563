// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.releaseAccordionSummary_iN1hN{display:flex;width:100%;justify-content:space-between}.releaseHeader_hRYEM{padding-top:2px;display:flex;align-items:center;color:#a6a6a6;font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/features/FirmwarePage/Files/Forms/CreateReleases/FirmwareReleaseHead/FirmwareReleaseHead.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA,+BACE,YAAA,CACA,UAAA,CACA,6BAAA,CAGF,qBACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,aCSQ,CDRR,gBAAA","sourcesContent":["@import '/src/styles/variables.scss';\n\n.releaseAccordionSummary {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.releaseHeader {\n  padding-top: 2px;\n  display: flex;\n  align-items: center;\n  color: $grey-40;\n  font-weight: bold;\n}\n","$primary: #a9cb00;\n$primary-dark: #80b000;\n$normal: #4f4f4f;\n\n$danger-light: #ffccc9;\n$danger: #e74c3c;\n$info-light: #abd7ff;\n$info: #46a3f9;\n$warning-background: #fee5cd;\n$warning-light: #ffeb8e;\n$warning: #f2994a;\n$background: #ffffff;\n\n$text: #1d1d1d;\n\n$border-input: #d3dcea;\n$border-disabled: #e7e7e7;\n$border-focused: $primary;\n$grey-10: #f3f3f3;\n$grey-20: #dfdfdf;\n$grey-30: #c4c4c4;\n$grey-40: #a6a6a6;\n$grey-60: #6e6e6e;\n$grey-70: #525252;\n$caret-color: #344966;\n\n$title-primary: #6e6e6e;\n$title-font-weight: 600;\n$title-font-size: 14px;\n$title-line-height: 16px;\n\n$normal-input-height: 32px;\n$form-input-height: 32px;\n$input-padding: 12px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"releaseAccordionSummary": `releaseAccordionSummary_iN1hN`,
	"releaseHeader": `releaseHeader_hRYEM`
};
export default ___CSS_LOADER_EXPORT___;
