// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldsArea_UAM1W{display:flex;flex-direction:column;gap:15px}.complexField_hk33X{display:flex;flex-direction:column;gap:10px}.shareInput_AlLYd{display:flex;flex-direction:row;gap:10px}.percentageSymbol_UACmE{padding-top:10px}`, "",{"version":3,"sources":["webpack://./src/features/FirmwarePage/Files/Forms/CreateReleases/FirmwareRelease/FirmwareRelease.module.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,oBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,kBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,wBACE,gBAAA","sourcesContent":["@import '/src/styles/variables.scss';\n\n.fieldsArea {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.complexField {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.shareInput {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n}\n\n.percentageSymbol {\n  padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldsArea": `fieldsArea_UAM1W`,
	"complexField": `complexField_hk33X`,
	"shareInput": `shareInput_AlLYd`,
	"percentageSymbol": `percentageSymbol_UACmE`
};
export default ___CSS_LOADER_EXPORT___;
