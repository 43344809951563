import { Accordion, AccordionDetails } from '@mui/material';
import React, { useState } from 'react';

import { TargetType } from 'api/firmware-api';
import { UseApplicationFormReturn } from 'helpers/useApplicationForm';
import { FirmwareReleaseHead } from '../FirmwareReleaseHead/FirmwareReleaseHead';
import { FirmwareReleaseModel, FirmwareReleases } from '../utils';
import { CountriesField } from './CountriesField/CountriesField';
import { DistributionWaysField } from './DistributionWaysField/DistributionWaysField';
import { PartialReleasePercentageField } from './PartialReleasePercentageField/PartialReleasePercentageField';
import { PrismaCloudStagesField } from './PrismaCloudStagesField/PrismaCloudStagesField';
import { StartDateField } from './StartDateField/StartDateField';
import { TargetTypeField } from './TargetTypeField/TargetTypeField';
import { WhiteListField } from './WhiteListField/WhiteListField';

import styles from './FirmwareRelease.module.scss';

export type FirmwareReleaseProps = {
  index: number;
  onDuplicate: (release: FirmwareReleaseModel) => void;
  onDelete: (index: number) => void;
  form: UseApplicationFormReturn<FirmwareReleases, any>;
};

export const FirmwareRelease: React.FC<FirmwareReleaseProps> = ({ index, form, onDuplicate, onDelete }) => {
  const { watch } = form;
  const release = watch(`releases.${index}`);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <Accordion expanded={isExpanded}>
      <FirmwareReleaseHead
        index={index}
        handleDelete={() => onDelete(index)}
        onClick={() => setIsExpanded(!isExpanded)}
        handleDuplicate={() => onDuplicate(release)}
      />
      <AccordionDetails className={styles.fieldsArea}>
        <StartDateField form={form} releaseIndex={index} />
        <TargetTypeField form={form} releaseIndex={index} release={release} />
        {release.type === TargetType.ByCountry && <CountriesField form={form} release={release} releaseIndex={index} />}
        {release.type === TargetType.ByWhiteList && (
          <>
            <WhiteListField form={form} release={release} releaseIndex={index} />
            <PartialReleasePercentageField form={form} release={release} releaseIndex={index} />
          </>
        )}
        <DistributionWaysField form={form} release={release} releaseIndex={index} />
        <PrismaCloudStagesField form={form} release={release} releaseIndex={index} />
      </AccordionDetails>
    </Accordion>
  );
};
