import { DeviceWhiteListDto, DistributionType, FirmwareTargetDto, TargetType } from 'api/firmware-api';
import { DropDownOption } from 'components/Select/utils';
import { addWhiteListFileExtension } from 'features/FirmwarePage/WhiteLists/utils';

export type FirmwareReleases = {
  releases: FirmwareReleaseModel[];
};

export type FirmwareReleaseModel = {
  id?: string;
  startDate: Date;
  distributionWays: DistributionWay[];
  type: TargetType;
  allCountries?: boolean;
  countries?: string[];
  whiteListId?: string;
  partialReleasePercentage?: number;
};

export const defaultRelease: FirmwareReleaseModel = {
  startDate: new Date(),
  type: TargetType.ByCountry,
  allCountries: false,
  countries: [],
  distributionWays: [],
};

export type DistributionWay = {
  id?: string;
  type: DistributionType;
  allStages: boolean;
  stages: string[];
};

export type ContriesSelector = 'Particular' | 'All' | null;
export type StagesSelector = 'Particular' | 'All' | null;

export const distributionTypesByWhiteList: DistributionType[] = [
  DistributionType.PrismaCloud,
  DistributionType.ClientPortal,
];

export const distributionTypes = Object.keys(DistributionType).map(
  (distributionType) => distributionType as DistributionType,
);

export const getDefaultContriesSelector = (release: FirmwareReleaseModel): ContriesSelector => {
  if (release.type === TargetType.ByWhiteList) {
    return null;
  }
  return release.countries && release.countries.length >= 0 ? 'Particular' : 'All';
};

export const getDefaultStagesSelector = (release: FirmwareReleaseModel): StagesSelector => {
  const pCloudDistributionWay = release.distributionWays.find((dW) => dW.type === DistributionType.PrismaCloud);
  if (!pCloudDistributionWay) {
    return null;
  }
  return pCloudDistributionWay.stages && pCloudDistributionWay.stages.length >= 0 ? 'Particular' : 'All';
};

export const firmwareTargetDtoToModel = (target: FirmwareTargetDto): FirmwareReleaseModel => ({
  id: target.id,
  startDate: new Date(target.startDate),
  type: target.type,
  allCountries: !target.countryTarget?.countries,
  countries: target.countryTarget?.countries ?? [],
  whiteListId: target.whiteListTarget?.whiteList.id,
  partialReleasePercentage: target.whiteListTarget?.partialReleasePercentage,
  distributionWays: target.distributionWays.map(
    (dW): DistributionWay => ({
      id: dW.id,
      type: dW.type,
      allStages: !dW.byPrismaCloud?.stages,
      stages: dW.byPrismaCloud?.stages ?? [],
    }),
  ),
});

export const firmwareTargetModelToDto = (release: FirmwareReleaseModel): FirmwareTargetDto => {
  const isTargetTypeByCountry = release.type === TargetType.ByCountry;

  return {
    id: release.id,
    startDate: release.startDate.toISOString(),
    distributionWays: release.distributionWays.map(distributionWayModelToDto),
    type: release.type,
    countryTarget: isTargetTypeByCountry
      ? { countries: release.allCountries ? undefined : release.countries }
      : undefined,
    whiteListTarget:
      !isTargetTypeByCountry && release.partialReleasePercentage
        ? {
            partialReleasePercentage: release.partialReleasePercentage,
            whiteList: {
              id: release.whiteListId!,
              name: '',
              description: '',
            },
          }
        : undefined,
  };
};

export const distributionWayModelToDto = (distribution: DistributionWay) => ({
  id: distribution.id,
  type: distribution.type,
  byPrismaCloud:
    distribution.type === DistributionType.PrismaCloud
      ? { stages: distribution.allStages ? undefined : distribution.stages }
      : undefined,
});

export const mapWhiteListsToDropDownOption = (whiteLists: DeviceWhiteListDto[]) =>
  whiteLists.map(
    (whiteList): DropDownOption => ({ value: whiteList.id, label: addWhiteListFileExtension(whiteList.name) }),
  );

export const getStagesDefaultValueByStagesSelector = (stagesSelector: StagesSelector): string[] | undefined =>
  stagesSelector === 'Particular' ? [] : undefined;

export const changeDistributionWays = (
  type: DistributionType,
  isChecked: boolean,
  currentDW: DistributionWay[],
): DistributionWay[] =>
  isChecked ? [...currentDW, { type: type, allStages: true, stages: [] }] : currentDW.filter((x) => x.type !== type);

export type DistributionTypeDescription = {
  type: DistributionType;
  label: string;
  hint?: string;
};
