import { Button } from '@mui/material';
import React, { useState } from 'react';

import {
  DeviceFirmwareGeneration,
  DistributionType,
  GenerationsInfoMap,
  getDistributionConstraints,
  getFirmwareGenerationInfo,
} from 'api/firmware-api';
import { AppModal } from 'components/AppModal/AppModal';
import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import {
  useAvailableTargetsQuery,
  useInvalidateFirmwarePageQuery,
} from 'helpers/react-query/notifications-query-hooks';
import { useIsoCodes } from 'helpers/useIsoCodes';
import { distributionTypes } from '../Forms/CreateReleases/utils';
import { UploadFileForm } from '../Forms/UploadFileForm/UploadFileForm';

import styles from './TopPanel.module.scss';

type Props = {
  selectedCountries: string[];
  onSelectedCountriesChange: (countries: string[]) => void;
  selectedDistributionTypes: DistributionType[];
  onSelectedDistributionTypesChange: (distributionTypes: DistributionType[]) => void;
  selectedStages: string[];
  onSelectedStagesChange: (stages: string[]) => void;
  selectedGenerations: DeviceFirmwareGeneration[];
  onSelectedGenerationsChange: (generation: DeviceFirmwareGeneration[]) => void;
};

export const TopPanel: React.FC<Props> = ({
  selectedCountries,
  onSelectedCountriesChange,
  selectedDistributionTypes,
  onSelectedDistributionTypesChange,
  selectedStages,
  onSelectedStagesChange,
  selectedGenerations,
  onSelectedGenerationsChange,
}) => {
  const { getCountryName } = useIsoCodes();
  const stages = useAvailableTargetsQuery()?.data?.stages?.map((x) => x.name) ?? [];
  const countries = useAvailableTargetsQuery()?.data?.countries ?? [];

  const [isUploadFileFormOpen, setUploadFileFormOpen] = useState<boolean>(false);
  const invalidate = useInvalidateFirmwarePageQuery();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  return (
    <div className={styles.topPanel}>
      <MultiSelect
        onChange={(val) => onSelectedGenerationsChange(val as DeviceFirmwareGeneration[])}
        options={GenerationsInfoMap.map((x) => x.firmwareGeneration)}
        placeholder={'All generations'}
        value={selectedGenerations}
        labelStrategy={(x) => getFirmwareGenerationInfo(x as DeviceFirmwareGeneration).name}
      />
      <MultiSelect
        onChange={(val) => onSelectedDistributionTypesChange(val as DistributionType[])}
        options={distributionTypes}
        placeholder={'All distribution types'}
        value={selectedDistributionTypes}
        labelStrategy={(value) => getDistributionConstraints(value as DistributionType).label}
      />
      <MultiSelect
        onChange={onSelectedCountriesChange}
        options={countries}
        placeholder={'All countries'}
        value={selectedCountries}
        labelStrategy={getCountryName}
      />
      <MultiSelect
        onChange={onSelectedStagesChange}
        options={stages}
        placeholder={'All stages'}
        value={selectedStages}
      />
      <Button disableRipple onClick={() => setUploadFileFormOpen(true)} children={'Upload new FW'} />
      <AppModal
        open={isUploadFileFormOpen}
        onClose={() => setUploadFileFormOpen(false)}
        preventClose={isFormSubmitting}
        dialogPaperClassName={styles.uploadModalDialogPaper}
      >
        <UploadFileForm
          setFormLocked={setIsFormSubmitting}
          onSubmitSuccess={() => {
            invalidate();
            setUploadFileFormOpen(false);
          }}
          onClose={() => {
            setUploadFileFormOpen(false);
          }}
        />
      </AppModal>
    </div>
  );
};
