import { AccordionSummary, Button } from '@mui/material';
import React from 'react';

import ArrowDownIcon from 'assets/icons/dropdown-arrow.svg';

import styles from './FirmwareReleaseHead.module.scss';

export type FirmwareReleaseHeadProps = {
  onClick: () => void;
  index: number;
  handleDelete: () => void;
  handleDuplicate: () => void;
};

export const FirmwareReleaseHead: React.FC<FirmwareReleaseHeadProps> = ({
  onClick,
  index,
  handleDelete,
  handleDuplicate,
}) => (
  <AccordionSummary sx={{ marginLeft: '6px' }} expandIcon={<ArrowDownIcon height={20} width={20} />} onClick={onClick}>
    <div className={styles.releaseAccordionSummary}>
      <div className={styles.releaseHeader}>Release {index + 1}</div>
      <div>
        <Button
          sx={{
            color: '#6e6e6e',
            [':hover']: {
              backgroundColor: 'transparent',
              color: '#6e6e6e',
            },
          }}
          variant="text"
          key={'delete'}
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button
          sx={{
            color: '#80b000',
            [':hover']: {
              backgroundColor: 'transparent',
              color: '#80b000',
            },
          }}
          variant="text"
          key={'duplicate'}
          onClick={(e) => {
            handleDuplicate();
            e.stopPropagation();
          }}
        >
          Duplicate
        </Button>
      </div>
    </div>
  </AccordionSummary>
);
