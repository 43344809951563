import React from 'react';
import { Controller } from 'react-hook-form';

import { Field } from 'components/Field/Field';
import { FlagRadioGroup } from 'components/FlagRadioGroup/FlagRadioGroup';
import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { useAvailableTargetsQuery } from 'helpers/react-query/notifications-query-hooks';
import { UseApplicationFormReturn } from 'helpers/useApplicationForm';
import { useIsoCodes } from 'helpers/useIsoCodes';
import { FirmwareReleaseModel, FirmwareReleases } from '../../utils';

import releaseStyles from './../FirmwareRelease.module.scss';

export type CountriesFieldProps = {
  releaseIndex: number;
  release: FirmwareReleaseModel;
  form: UseApplicationFormReturn<FirmwareReleases, any>;
};

export const CountriesField: React.FC<CountriesFieldProps> = ({
  form: { control, register, formState },
  release,
  releaseIndex,
}) => {
  const { getCountryName } = useIsoCodes();
  const countries = useAvailableTargetsQuery()?.data?.countries ?? [];
  const errors = formState.errors.releases?.[releaseIndex];

  const countriesField = register(`releases.${releaseIndex}.countries`, {
    required: { value: !release.allCountries, message: 'Required' },
  });

  return (
    <Field title={'Countries'} fieldClassName={releaseStyles.complexField}>
      <FlagRadioGroup
        control={control}
        name={`releases.${releaseIndex}.allCountries`}
        labelStrategy={(val: boolean) => (val ? 'All' : 'Specific')}
      />
      {!release.allCountries && (
        <Controller
          control={control}
          name={countriesField.name}
          render={({ field: { ref, ...field } }) => (
            <MultiSelect
              {...field}
              placeholder={'None are selected'}
              options={countries}
              error={!!errors?.countries}
              helperText={errors?.countries?.message}
              labelStrategy={getCountryName}
            />
          )}
        />
      )}
    </Field>
  );
};
