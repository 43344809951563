import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export type FlagRadioGroupProps<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>> = {
  control: Control<TFieldValues>;
  name: TFieldName;
  labelStrategy: (value: boolean) => React.ReactNode;
};

export const FlagRadioGroup = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  control,
  name,
  labelStrategy,
}: FlagRadioGroupProps<TFieldValues, TFieldName>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, ...field } }) => (
      // react-hook-form uses radio input value as string, so we convert it manually
      <RadioGroup row {...field} onChange={(e) => onChange(e.currentTarget.value === 'true')}>
        <FormControlLabel value={false} control={<Radio />} label={labelStrategy(false)} />
        <FormControlLabel value={true} control={<Radio />} label={labelStrategy(true)} />
      </RadioGroup>
    )}
  />
);
