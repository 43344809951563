import { radioClasses } from '@mui/material';
import buttonClasses from '@mui/material/Button/buttonClasses';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { paginationItemClasses } from '@mui/material/PaginationItem';
import { tabClasses } from '@mui/material/Tab';
import toggleButtonClasses from '@mui/material/ToggleButton/toggleButtonClasses';
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import React, { ReactNode } from 'react';
import { match } from 'ts-pattern';

import { PickersActionBarAction } from '@mui/x-date-pickers-pro';
import CalendarIcon from 'assets/icons/calendar.svg';
import { RadioButtonIcon, radioButtonIconClasses } from './components/common/icons/RadioButtonIcon';

const getOutlinedInputClasses = (colors: { main: string; light: string; dark: string }) => ({
  backgroundColor: colors.light,
  [`& .${outlinedInputClasses.input}`]: {
    color: colors.main,
  },
  ['&:hover']: {
    backgroundColor: colors.light,
  },
  [`&.${outlinedInputClasses.focused}`]: {
    backgroundColor: colors.light,
  },
  [`& .${outlinedInputClasses.input}::placeholder`]: {
    color: colors.main,
    opacity: 1,
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: colors.main,
  },
  [`&:hover:not(.${outlinedInputClasses.focused}):not(.${outlinedInputClasses.disabled}) .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: colors.main,
    },
  [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: colors.dark,
    borderWidth: 1,
  },
});

export const primaryHover = '#bede1e';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#a9cb00',
      dark: '#80b000',
      light: '#faffe2',
    },
    secondary: {
      light: grey[50],
      dark: grey[400],
      main: grey[300],
    },
    warning: {
      light: '#fffde7',
      dark: '#f3a354',
      main: '#f3a354',
    },
    error: {
      light: '#ffefef',
      dark: '#ff6666',
      main: '#ff8787',
    },
    action: {
      disabled: '#d9d9d9',
      disabledBackground: '#f6f6f6',
      hover: '#80b000',
    },
    text: {
      disabled: '#979797',
    },
    grey: {
      ...grey,
      300: '#DFDFDF',
      400: '#A6A6A6',
      500: '#979797',
      800: '#525252',
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 16,
          fontWeight: 300,
          [`&.${inputBaseClasses.inputAdornedStart}`]: {
            paddingLeft: 0,
          },
          [`&.${inputBaseClasses.disabled}::placeholder`]: {
            opacity: 1,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          minHeight: 'unset',
          [`& .${tabClasses.root}.${tabClasses.selected}`]: {
            color:
              ownerState.textColor === 'inherit' ? 'inherit' : theme.palette[ownerState.textColor ?? 'primary'].dark,
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          fontSize: 16,
          whiteSpace: 'nowrap',
          paddingLeft: '10px',
          alignItems: 'center',
          minWidth: 120,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: '#fff',
          ...(ownerState.color === 'primary' && {
            ['&:hover']: {
              backgroundColor: theme.palette.primary.light,
            },
            [`&.${outlinedInputClasses.focused}`]: {
              backgroundColor: theme.palette.primary.light,
            },
            [`&:hover:not(.${outlinedInputClasses.focused}):not(.${outlinedInputClasses.disabled}) .${outlinedInputClasses.notchedOutline}`]:
              {
                borderColor: primaryHover,
              },
            [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
            },
          }),
          [`&.${outlinedInputClasses.disabled}`]: {
            backgroundColor: theme.palette.action.disabledBackground,
          },
          ...((ownerState.color === 'error' || ownerState.error) && getOutlinedInputClasses(theme.palette.error)),
          ...(ownerState.color === 'warning' && getOutlinedInputClasses(theme.palette.warning)),
        }),
        input: ({ theme }) => ({
          height: 26,
          padding: theme.spacing(0.75, 2),
        }),
        notchedOutline: {
          borderWidth: 1,
          borderColor: 'rgba(0, 0, 0, 0.13)',
          top: 0,
          [`& *`]: {
            display: 'none',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height: 32,
          transition: theme.transitions.create('all', { duration: theme.transitions.duration.shortest }),
          fontSize: '14px',
          fontWeight: 300,
          borderRadius: 4,
          color: match(ownerState)
            .when(
              (x) => x.color === 'primary' && x.variant === 'contained',
              () => '#fff',
            )
            .when(
              (x) => x.variant === 'outlined',
              (x) => (x.color === 'inherit' ? 'inherit' : theme.palette[x.color ?? 'primary'].dark),
            )
            .otherwise((x) => (x.color === 'inherit' ? 'inherit' : theme.palette[x.color ?? 'primary'].contrastText)),
          textTransform: 'none',
          boxShadow: 'none',
          border: match(ownerState)
            .when(
              (x) => x.variant === 'outlined',
              (x) => `1px solid ${x.color === 'inherit' ? 'inherit' : theme.palette[x.color ?? 'primary'].dark}`,
            )
            .otherwise((x) => '0px'),
          ['&:hover']: {
            backgroundColor: match(ownerState)
              .when(
                (x) => x.variant === 'contained',
                (x) => (x.color === 'inherit' ? 'inherit' : theme.palette[x.color ?? 'primary'].dark),
              )
              .when(
                (x) => x.variant === 'text',
                (x) => (x.color === 'inherit' ? 'inherit' : theme.palette[x.color ?? 'primary'].dark),
              )
              .otherwise((x) => (x.color === 'inherit' ? 'inherit' : theme.palette[x.color ?? 'primary'].light)),
            color: match(ownerState)
              .when(
                (x) => x.variant === 'text',
                () => '#fff',
              )
              .when(
                (x) => x.variant === 'contained',
                () => '#fff',
              )
              .when(
                (x) => x.variant == 'outlined',
                (x) => (x.color === 'inherit' ? 'inherit' : theme.palette[x.color ?? 'primary'].dark),
              )
              .otherwise((x) => (x.color === 'inherit' ? 'inherit' : theme.palette[x.color ?? 'primary'].contrastText)),
            boxShadow: 'none',
          },
          [`&.${buttonClasses.disabled}`]: {
            ...(ownerState.variant === 'contained' && {
              color: '#fff',
              backgroundColor: theme.palette.action.disabled,
            }),
          },
          [`&.${buttonClasses.containedError}`]: {
            ...(ownerState.variant === 'contained' && {
              color: '#fff',
              '&:hover': {
                backgroundColor: theme.palette.error.dark,
              },
            }),
          },
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          [`&.${toggleButtonClasses.selected}`]: {
            color: '#fff',
          },
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          zIndex: 'modal',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        icon: <RadioButtonIcon />,
        checkedIcon: <RadioButtonIcon checked={true} />,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          padding: '3px 9px 3px 9px',
          [`& .${radioButtonIconClasses.border}`]: {
            transition: theme.transitions.create(['fill', 'stroke'], {
              easing: theme.transitions.easing.easeIn,
              duration: theme.transitions.duration.shortest,
            }),
            fill: '#fff',
            stroke: theme.palette.grey[400],
          },
          [`& .${radioButtonIconClasses.dot}`]: {
            fill: theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary'].main,
          },
          [`&.${radioClasses.disabled}`]: {
            cursor: 'default',
            [`& .${radioButtonIconClasses.border}`]: {
              fill: '#fff',
              stroke: theme.palette.action.disabled,
            },
            [`& .${radioButtonIconClasses.dot}`]: {
              fill: theme.palette.action.disabled,
            },
          },
          [`&:hover:not(${radioClasses.disabled})`]: {
            backgroundColor: 'transparent',
            [`& .${radioButtonIconClasses.border}`]: {
              fill: theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary'].light,
              stroke: theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary'].main,
            },
            [`& .${radioButtonIconClasses.dot}`]: {
              fill: theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary'].dark,
            },
          },
        }),
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: 355,
        },
      },
    },
    MuiDateRangeCalendar: {
      defaultProps: {
        dayOfWeekFormatter: (day) => day,
      },
      styleOverrides: {
        monthContainer: {
          '&:not(:last-of-type)': {
            borderRight: 'none !important;',
          },
          width: 355,
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        dayOfWeekFormatter: (day) => day,
        // By default it is '@media (pointer: fine)' in selenium tests pointer is not 'fine',
        // to do workaround for this problem force desktop version of date picker
        // Now it is not important because we support only desktop version
        desktopModeMediaQuery: '@media only screen and (min-width: 2px)',
        slots: {
          openPickerIcon: CalendarIcon,
        },
        slotProps: {
          textField: {
            fullWidth: true,
          },
          openPickerButton: {
            disableRipple: true,
          },
          actionBar: {
            actions: ['clear', 'accept'] as PickersActionBarAction[],
          },
          inputAdornment: {
            // put calendar button before date input
            position: 'end',
          },
        },
      },
    },
    MuiDateTimePicker: {
      defaultProps: {
        slotProps: {
          textField: {
            fullWidth: true,
          },
          openPickerButton: {
            disableRipple: true,
          },
          actionBar: {
            actions: ['clear', 'accept'] as PickersActionBarAction[],
          },
        },
        slots: {
          openPickerIcon: CalendarIcon,
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          transition: theme.transitions.create('all', { duration: theme.transitions.duration.shortest }),
          [`&.${paginationItemClasses.selected}`]: {
            color: '#ffffff',
          },
          [`&:not(.${paginationItemClasses.ellipsis}):hover`]: {
            color: '#fff',
          },
          [`&.${paginationItemClasses.ellipsis}`]: {
            cursor: 'default',
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            height: 14,
            paddingTop: '7px !important',
            paddingBottom: '7px !important',
            fontSize: 14,
          },
          '& .MuiInputBase-root': {
            padding: 2,
            paddingLeft: 6,
            fontSize: 14,
            borderRadius: 6,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            height: '100%',
            top: '0px',
            borderColor: '#d3dcea',
            '& legend': {
              display: 'none',
            },
          },
          width: '100%',
        },
        option: ({ theme }) => ({
          '&.Mui-focused': {
            backgroundColor: `${theme.palette.grey[100]} !important`,
          },
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '0px !important',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          paddingTop: 2,
          color: '#e5fd6e',
        },
        label: {
          padding: '0 8px',
        },
        root: ({ theme, ownerState }) => ({
          ...(ownerState.variant === 'filled'
            ? {
                ['&:hover']: {
                  '& .MuiChip-deleteIcon': {
                    color: 'black',
                  },
                  backgroundColor: '#FFCCC9',
                },
                backgroundColor: '#e5fd6e',
              }
            : {
                ['&:hover']: {
                  '& .MuiChip-deleteIcon': {
                    color: 'black',
                  },
                  borderColor: theme.palette.primary.main,
                },
                borderColor: theme.palette.grey[400],
              }),
          borderRadius: 4,
          fontSize: 14,
          margin: 2,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: ownerState.color,
          '&.Mui-error': {
            color: theme.palette.error.dark,
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        row: {
          gap: '35px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          ['&.Mui-expanded']: {
            margin: '0px',
          },
          ['&:before']: {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '40px',
          borderBottom: '1px solid #DFDFDF',
          padding: '0px',
          ['&.Mui-expanded']: {
            minHeight: '40px',
          },
        },
        content: {
          margin: '0px',
          ['&.Mui-expanded']: {
            margin: '0px',
            minHeight: '0px',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '20px 0px',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
